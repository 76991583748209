<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>

        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.text100Required"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Nombre de comercial*"
                      v-model="data.trade_name"
                      :rules="rules.text100Required"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Tipo de proveedor*"
                      v-model="data.provider_type_id"
                      :rules="rules.required"
                      :items="provider_types"
                      item-value="id"
                      :item-text="(item) => item.provider_type"
                      :loading="provider_types_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Concentrador*"
                      v-model="data.provider_brand_id"
                      :items="provider_brands"
                      item-value="id"
                      :item-text="(item) => item.provider_brand"
                      :loading="provider_brands_loading"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="4"
                    v-if="data.provider_type_id === 4"
                  >
                    <v-select
                      label="Formato de estado de cuenta*"
                      v-model="data.status_account_format_id"
                      :items="status_account_formats"
                      item-value="id"
                      :item-text="(item) => item.status_account_format"
                      :loading="status_account_formats_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="data.observation"
                      label="Observación"
                      dense
                      rows="1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto" :disabled="login.role_id != 15">
              <v-card-title>
                <h2 class="text-caption" v-text="'BANCO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Banco"
                      v-model="data.bank_id"
                      :items="banks"
                      item-value="id"
                      :item-text="(v) => v.bank"
                      :loading="banks_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Número de cuenta"
                      v-model="data.account_number"
                      type="text"
                      maxlength="15"
                      counter
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="CLABE"
                      v-model="data.clabe"
                      type="text"
                      maxlength="18"
                      counter
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="data.discount"
                      :label="'Descuento'"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <span
                      class="text-caption"
                      v-text="'Cuenta bancaria (PDF)'"
                    />
                    <br />
                    <input
                      accept=".pdf"
                      label="Nombre*"
                      type="file"
                      @change="select_file($event, 0, 'bank_account')"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <span class="text-caption" v-text="'Valid. cuenta (PDF)'" />
                    <br />
                    <input
                      accept=".pdf"
                      label="Nombre*"
                      type="file"
                      @change="select_file($event, 0, 'account_validation')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'DOMICILIO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Calle"
                      v-model="data.street"
                      :rules="rules.text100"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Número exterior"
                      v-model="data.external_number"
                      :rules="rules.text20"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Número interior"
                      v-model="data.internal_number"
                      :rules="rules.text20"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Colonia"
                      v-model="data.suburb"
                      :rules="rules.text50"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado*"
                      v-model="data.entity_id"
                      :rules="rules.required"
                      :items="entities"
                      item-value="id"
                      :item-text="(item) => item.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities()"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio*"
                      v-model="data.municipality_id"
                      :rules="rules.required"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(item) => item.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Código postal"
                      v-model="data.zip"
                      :rules="rules.text5"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <span
                      class="text-caption"
                      v-text="'Comp. domicilio (PDF)'"
                    />
                    <br />
                    <input
                      accept=".pdf"
                      label="Nombre*"
                      type="file"
                      @change="select_file($event, 0, 'address')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'LEGAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Razón social"
                      v-model="data.legal_name"
                      :rules="rules.text100"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="RFC"
                      v-model="data.code_tax"
                      :rules="rules.code_tax"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Domicilio fiscal"
                      v-model="data.legal_address"
                      :rules="rules.text150"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <span class="text-caption" v-text="'RFC (PDF)'" />
                    <br />
                    <input
                      accept=".pdf"
                      label="Nombre*"
                      type="file"
                      @change="select_file($event, 0, 'code_tax')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONTACTO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="8">
                    <v-text-field
                      label="Nombre de contacto"
                      v-model="data.contact_name"
                      :rules="rules.text100"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Teléfono"
                      v-model="data.phone"
                      :rules="rules.text10"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'OTROS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="IVA"
                      v-model="data.iva"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Día de pago*"
                      v-model="data.pay_day"
                      type="number"
                      dense
                      :rules="rules.required"
                      min="1"
                      max="31"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Factor CPT"
                      v-model="data.cpt_factor"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="NET ID"
                      v-model="data.net_id"
                      min="1"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Descuento en factura'"
                      v-model="data.bill_discount"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-select
                      :label="`Tipo de descuento*`"
                      v-model="data.fixed_discount"
                      :items="discounts"
                      item-value="id"
                      :item-text="(item) => item.discount"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Porcentaje de descuento"
                      v-model="data.fund_without_discount"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Formato DDC en factura*"
                      v-model="data.ddc_format"
                      :items="ddc_formats"
                      item-value="id"
                      :item-text="(v) => v.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Sin Nota de crédito o posterior'"
                      v-model="data.credit_note_after"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Descuento, deducible y coaseguro'"
                      v-model="data.ddc"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      label="Monto exento"
                      v-model="data.exempt_amount"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'IVA, deducible y coaseguro'"
                      v-model="data.idc"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Convenio'"
                      v-model="data.agreement"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" v-if="login.role_id === 1">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Segunda firma red'"
                      v-model="data.revision"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="C.S."
                      v-model="data.cs"
                      maxlength="10"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Sumar deducible y coseguro'"
                      v-model="data.add_deductible_coinsurance"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'No verificar monto nota de crédito'"
                      v-model="data.notes_verify_amount"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Aumento de suma automático'"
                      v-model="data.automatic_authorization"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Porc. cobro particulares"
                      v-model="data.particular_payment"
                      type="number"
                      min="0"
                      max="99"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Envio correos cobro particulares'"
                      v-model="data.service_particular_email"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Generar carta servicio automáticamente'"
                      v-model="data.auto_letter_generated"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Módulo SM'"
                      v-model="data.with_module"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'CONDICIONES URGENCIAS SENTIDAS'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Activo'"
                      v-model="data.express_letter"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.express_letter" cols="12" xs="12" md="3">
                    <v-text-field
                      label="Monto"
                      v-model="data.express_amount"
                      :rules="rules.required"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.express_letter" cols="12">
                    <v-textarea
                      v-model="data.express_caption"
                      label="Observación"
                      dense
                      rows="1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'FACTURAS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    v-for="(item, i) in data.invoices_receptions"
                    :key="i"
                  >
                    <v-row v-if="item.active" dense>
                      <v-col cols="12" xs="12" md="5">
                        <v-select
                          :label="`Día de recepción* (${i + 1})`"
                          v-model="item.day_id"
                          :rules="rules.required"
                          :items="days"
                          item-value="id"
                          :item-text="(item) => item.day"
                          :loading="days_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="item.start"
                          label="Hora de inicio*"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          v-model="item.end"
                          label="Hora de término*"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteInvoiceReception(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar día de recepción (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addRecepcion"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar día de recepción
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'ENVIO DE CORREOS ELECTRÓNICOS'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    v-for="(provider_email, i) in data.provider_emails"
                    :key="i"
                  >
                    <v-row v-if="provider_email.active" dense>
                      <v-col cols="5">
                        <v-text-field
                          v-model="provider_email.email"
                          :label="`Correo electrónico* (${i + 1})`"
                          :rules="rules.email"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col cols="5">
                        <v-switch
                          class="mt-0 pt-0"
                          v-model="provider_email.for_service"
                          :label="
                            'Uso: ' +
                            (provider_email.for_service
                              ? 'Servicio particular'
                              : ' Carta ingresos')
                          "
                          dense
                        />
                      </v-col>
                      <v-col cols="2" align-self="center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="emailDelete(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar correo electrónico (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="emailAdd"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar correo electrónico
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "providers",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        name: "",
        street: "",
        external_number: "",
        internal_number: "",
        suburb: "",
        city: "",
        zip: "",
        legal_name: "",
        code_tax: "",
        legal_address: "",
        phone: "",
        trade_name: "",
        contact_name: "",
        account_number: "",
        clabe: "",
        discount: false,
        ddc: false,
        iva: 0,
        idc: false,
        pay_day: "1",
        agreement: false,
        exempt_amount: false,
        bill_discount: false,
        fund_with_discount: 0,
        fund_without_discount: 0,
        cpt_factor: 1,
        provider_type_id: null,
        entity_id: null,
        invoices_receptions: [],
        net_id: null,
        cs: null,
        provider_brand_id: null,
        bank_id: null,
        ddc_format: null,
        credit_note_after: false,
        add_deductible_coinsurance: false,
        notes_verify_amount: false,
        automatic_authorization: false,
        express_letter: false,
        express_amount: 0,
        express_caption: "",
        provider_emails: [],
        service_particular_email: false,
        auto_letter_generated: false,
        with_module: false,
      },
      rules: {},
      municipalities: [],
      municipalities_loading: false,
      entities: [],
      entities_loading: true,
      provider_types: [],
      provider_types_loading: true,
      days: [],
      days_loading: true,
      status_account_formats: [],
      status_account_formats_loading: true,
      provider_brands: [],
      provider_brands_loading: true,
      banks: [],
      banks_loading: true,
      discounts: [
        { id: 0, discount: "Variable" },
        { id: 1, discount: "Fijo" },
      ],
      ddc_formats: [
        {
          id: null,
          name: "NINGUNO",
        },
        {
          id: 1,
          name: "ANGELES",
        },
        {
          id: 2,
          name: "OCA",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    select_file(event, i, file) {
      let position = "document_" + file + "_" + i;
      this.data[position] = event.target.files[0];
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index("municipalities/" + this.data.entity_id, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.municipalities = response.data;
        this.municipalities_loading = false;
      });
    },
    handleSubmit() {
      //validate form data
      console.log("TEST");
      if (this.$refs.form_data.validate()) {
        console.log("TEST2");
        let valid = true;

        console.log(this.data.pay_day);
        valid = parseInt(this.data.pay_day) > 0;

        if (valid) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;
                //store or update action
                storeUpdate(
                  this.page_route,
                  this.login.token,
                  this.id,
                  this.data,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  response.success
                    ? this.$router.push({
                        name: `${this.page_route}${
                          this.store_mode ? "" : ".view"
                        }`,
                        params: { id: this.id },
                      })
                    : console.log(response.message);
                });

                this.loading = false;
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "El día de pago es obligatorio y debe ser mayor a cero"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    addRecepcion() {
      this.data.invoices_receptions.push({
        id: null,
        start: "",
        end: "",
        day_id: 1,
        active: 1,
      });
    },
    deleteInvoiceReception(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el día de recepción (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.invoices_receptions[i].id === null)
              this.data.invoices_receptions.splice(i, 1);
            else this.data.invoices_receptions[i].active = false;
          }
        });
    },
    emailAdd() {
      this.data.provider_emails.push({
        id: null,
        active: true,
        email: "",
        end: "",
        provider_id: this.data.id,
        for_service: true,
      });
    },
    emailDelete(i) {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma eliminar el correo electrónico (${i + 1})?`)
        )
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.provider_emails[i].id === null)
              this.data.provider_emails.splice(i, 1);
            else this.data.provider_emails[i].active = false;
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities_loading = false;
    });
    catalog("banks", this.login.token).then((response) => {
      this.banks = response;
      this.banks.push({
        id: null,
        bank: "PENDIENTE",
      });
      this.banks_loading = false;
    });
    catalog("status_account_formats", this.login.token).then((response) => {
      this.status_account_formats = response;
      this.status_account_formats.push({
        id: null,
        status_account_format: "NINGUNO",
      });
      this.status_account_formats_loading = false;
    });
    catalog("provider_types", this.login.token).then((response) => {
      this.provider_types = response;
      this.provider_types_loading = false;
    });
    catalog("days", this.login.token).then((response) => {
      this.days = response;
      this.days_loading = false;
    });
    index("providers/brands", this.login.token, []).then((response) => {
      this.provider_brands = response.data;
      this.provider_brands.push({
        id: null,
        provider_brand: "NINGUNO",
      });
      this.provider_brands_loading = false;
    });
    //compare store or update
    if (!this.store_mode) {
      this.loading = true;
      //get data for ID
      show(this.page_route, this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
        this.getMunicipalities();
      });
    }
    this.rules = rules();
  },
};
</script>